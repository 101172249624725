/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


.gm-ui-hover-effect {
    display: none !important;
}

html {
    overflow-x: clip;
}

input[type="color"] {
    -webkit-appearance: none;
    
}

input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}

input[type="color"]::-webkit-color-swatch {
    border-radius: 0.375rem;
}